import React from "react"
import Fade from 'react-reveal/Fade';
import Title from "./Common/Title"

function Media({ data, themeColor }) {

  return (
    <section id="media">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center title">
            <Title title={data.title} themeColor={themeColor} />
          </div>
          <div className="d-flex flex-wrap justify-content-center w-100 media-light pb-5">
            {data.iframe.map((key, index) =>
              <div key={index} className="m-md-5 w-100">
                <div className="col-12 mb-3">
                  <Fade >
                    <h3>{key.name}</h3>
                  </Fade>
                </div>
                <div className="col-12  ">
                  <Fade >
                    <div className="video-container">
                      <iframe title={key.name} src={key.link} width="500" height="380" frameBorder="0"></iframe>
                    </div>
                  </Fade>
                </div>
              </div>)}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Media